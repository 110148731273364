<script lang="ts" setup>
import { Image as DatocmsImage } from "vue-datocms";
import { useBoosterHydrate } from '#imports';
import { useSiteStore } from '@/stores/siteStore'
import { useSeo } from '@/composables/useSeo'

const siteStore = useSiteStore()
import useGsapAnimations from '~/composables/gsapAnimations';

const scrollToTodayRef = ref(() => { });
const hydrate = useBoosterHydrate();
const EventListCritical = hydrate(() => import('@/components/EventList.vue'));

definePageMeta({
  layout: 'landing'
})
import { startOfDay, endOfDay, addDays, startOfTomorrow } from "date-fns";
import { toZonedTime, format } from "date-fns-tz";
import { useRoomMapping } from '@/composables/useRoomMapping';

import { useDateFormat } from '@vueuse/core';
const prettyFormat = (date: Date) =>
  useDateFormat(ref(date), "MMM D, YYYY h:mm a").value;

const today = startOfDay(new Date());
const startOfToday = startOfDay(today);

const tomorrow = addDays(today, 1)
const endOfTomorrow = endOfDay(tomorrow);

const { roomMapping, filteredRooms, isLoading, fetchRoomMappings } = useRoomMapping();





const QUERY = `{
  allRooms {
    name
    slug
  }
  homePage {
    _seoMetaTags {
      attributes
      content
      tag
    }
    heroHeader(markdown: true)
    heroHighlight {
      copy(markdown: true)
      cta {
        text
        link
      }
      image {
        title
        url
        responsiveImage(imgixParams: {w: 800, auto: format, fit: crop, ar: "2:1"}) {
          srcSet
          src
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          base64
        }
      }
    }
    bookingHeader
    bookingHighlight {
      copy(markdown: true)
      style
      cta {
        text
        link
      }
      image {
        url
        responsiveImage(imgixParams: {auto: format, fit: crop}) {
          srcSet
          src
          
          sizes
          src
          width
          height
          aspectRatio
          alt
          title
          base64
        } 
      }
    }
    joinCopy
    joinCta {
      link
      text
    }
    joinHeader
    joinPrice(markdown: false)
    highlights {
      ... on EventRecord {
        id
        description(markdown: true)
        endDate
        photo {
          url
          title
          responsiveImage(imgixParams: {auto: format, blur: "20", duotone: "f76666,ece0c7"}) {
            srcSet
            src
            sizes
            src
            width
            height
            aspectRatio
            alt
            title
            base64
          }
        }
        slug
        startDate
        title
      }
      
    }
  }
}
`;
const { data, error } = await useGraphqlQuery({ query: QUERY });
const EVENTS_QUERY = `
    query ($start: DateTime!, $end: DateTime!) {
    allEvents(
      filter: { startDate: { gte: $start, lte: $end } },
      orderBy: startDate_ASC
    ) {
      id
      title
      startDate
      endDate
      slug
      rooms {
        name
        slug
        id
      }
      photo {
        url
        title
      }
      categories
    }
  }`
const events = ref([]);
const fetchEvents = () => {

  const { data, error: fetchError } = useGraphqlQuery({
    query: EVENTS_QUERY,
    variables: { start: startOfToday.toISOString(), end: endOfTomorrow.toISOString() },
  });

  watchEffect(() => {
    if (fetchError.value) {
      console.error('Failed to fetch events', fetchError.value);
    } else if (data.value) {
      events.value = data.value.allEvents.map(event => ({
        ...event,
        start: new Date(event.startDate),
        end: new Date(event.endDate),
      }));
    }
  });

};

onMounted(async () => {
  fetchEvents();
  await fetchRoomMappings();
  try {
    const { scrollToToday, initHighlights, initHeroAnimation } = await useGsapAnimations();

    // Initialize all animations
    initHeroAnimation();
    initHighlights();

    // Store the scrollToToday function in our ref
    scrollToTodayRef.value = scrollToToday;
  } catch (error) {
    console.error('Failed to initialize animations:', error);
  }


});




watchEffect(() => {
  fetchEvents();
  if (data.value?.homePage && siteStore.siteData) {
    useSeo(data.value.homePage, siteStore.siteData);
  }
});


</script>
<template>
  <main class="">
    <h1 class="hidden">Tranzac</h1>
    <div @click="scrollToTodayRef"
      class="calendar-blob z-20 dark:calendar-blob-dark text-stone-100 cursor-pointer hidden md:flex absolute top-4 left-4 text-sm w-32 text-center h-32  items-center  transform transition-transform duration-300 hover:scale-105 leading-relaxed">
      <div>What&#8217;s happening at the Tranzac</div>
    </div>
    <div class="grid-container">

      <div class="relative isolate overflow-hidden px-6" critical>
        <!-- Hero section -->
        <div class="lg:mt-96 relative mx-auto lg:max-w-7xl pb-24 pt-10 sm:pb-40 lg:grid lg:grid-cols-2 gap-10 ">
          <div class="pt-10 lg:flex flex-wrap justify-end h-full z-20 relative">
            <!-- Highlight and CTA section -->
            <div id="highlight" class="flex flex-col h-full place-content-end z-50 opacity-0 translate-y-8">
              <div class="indent-10 lg:mt-96 text-xl md:text-3xl z-50" v-html="data.homePage.heroHighlight.copy"></div>
              <NuxtLink :to="data.homePage.heroHighlight.cta.link"
                class="oval-button -secondary md:mt-4 text-sm font-semibold leading-6 md:right-0 relative place-self-center md:place-self-end">
                {{ data.homePage.heroHighlight.cta.text }} <span aria-hidden="true">→</span>
              </NuxtLink>
            </div>

          </div>
          <div
            class="col-start-2 relative  mt-8 flex justify-center -mx-[calc(20%)] md:-mx-[calc(50%-20px)] lg:-mr-[calc(50%-3rem)]">
            <!-- DatocmsImage -->
            <DatocmsImage :data="data.homePage.heroHighlight.image.responsiveImage" critical
              img-class="w-1/2 h-auto object-cover rounded-md shadow-2xl relative" layout="responsive" :priority="true"
              :width="data.homePage.heroHighlight.image.responsiveImage.width"
              :height="data.homePage.heroHighlight.image.responsiveImage.height" />
            <div
              class="h-10 rounded-br-md absolute bottom-0 left-1/2 transform -translate-x-1/2 lg:top-0 lg:left-0 lg:transform-none bg-stone-950 bg-opacity-50 text-stone-400 p-2 rounded-tl-md mr-4 mb-4 lg:mr-0 lg:mb-0 text-center lg:text-left text-xs w-auto">
              <div class="flex flex-row items-center justify-center h-full">{{ data.homePage.heroHighlight.image.title
                }}</div>
            </div>

            <!-- Hero header -->
            <div id="hero"
              class="absolute p-20 md:p-0 lg:pr-56 inset-0 flex items-center justify-center text-center md:text-right text-3xl md:text-5xl font-display z-10 drop-shadow-md opacity-0 translate-y-8"
              v-html="data.homePage.heroHeader">
            </div>


          </div>

          <!-- Highlight and CTA section -->

        </div>
      </div>

      <!-- Today Section -->
      <section id="today" class="p-8 bg-stone-200 dark:bg-stone-800 grid-area-today">
        <div class="container mb-12 flex flex-row justify-between items-center w-full ">
          <h2 class=" inline-block">Calendar</h2>
          <div class=" inline-block">
            <NuxtLink class="oval-button text-xs rotate-6" to="/calendar">Full Calendar</NuxtLink>

          </div>

        </div>
        <EventListCritical :events="events" :page="0" :roomMapping="roomMapping" />
        <div class="flex justify-center pt-6">
          <NuxtLink class="oval-button mx-auto" to="/calendar">Full Calendar</NuxtLink>
        </div>
      </section>


      <!-- Rent Space Section -->
      <section data-scroll data-scroll-speed="0.5" id="rent-space" class="px-8 lg:px-0 lg:container md:mb-12">

        <h2 v-html="data.homePage.bookingHeader" />
        <div class="flex md:flex-row flex-col items-center p-0 mt-8">
          <div class="relative md:w-1/2  items-center w-full h-full">

            <DatocmsImage :data="data.homePage.bookingHighlight.image.responsiveImage"
              img-class="w-full rounded-md relative" />
            <div v-if="data.homePage.bookingHighlight.image.title"
              class="absolute bottom-0 right-0 lg:top-0 lg:left-0 bg-black bg-opacity-50 text-white p-2 rounded-tl-md mr-4 mb-4 md:right-1/2 md:transform md:translate-x-1/2 lg:mr-4 lg:mb-4 lg:translate-x-0 lg:transform-none">
              {{ data.homePage.bookingHighlight.image.title }}
            </div>

            <div
              :class="{ 'text-cream absolute inset-0 flex items-center justify-center p-4 indent-10 text-xl md:text-2xl lg:text-4xl lg:w-3/4 place-content-end h-full': data.homePage.bookingHighlight.style === 'overlay' }"
              v-html="data.homePage.bookingHighlight.copy" />
          </div>
          <div class="md:w-1/2 flex flex-col items-center justify-center">

            <!-- /list spaces for rent -->
            <div class=" my-8">
              <div v-for="room in filteredRooms" :key="room.slug"
                class="room-link flex flex-col items-center justify-center lg:mb-4">
                <NuxtLink :to="`/rentals/${room.slug}`" class="text-flamingo">
                  <div class="text-center">
                    <h3 class="text-2xl md:text-4xl lg:text-5xl">{{ room.name }}</h3>
                  </div>
                </NuxtLink>
              </div>
            </div>

            <a :href="data.homePage.bookingHighlight.cta.link" class="oval-button  mt-8 md:mt-0 md:rotate-12">{{
              data.homePage.bookingHighlight.cta.text
            }}</a>
          </div>
        </div>
      </section>

      <!-- Membership Section -->
      <section id="membership" class="lg:container p-8 overflow-visible">

        <div class="md:grid md:grid-cols-2 items-center justify-center">
          <div
            class="text-stone-900 dark:text-stone-300 order-2 lg:order-1 py-32 join-blob mx-auto md:-rotate-12 md:mt-0 text-center lg:text-left  ">
            <h2 v-html="data.homePage.joinHeader" />
            <div class="flex lg:flex-row flex-col  md:items-center  md:justify-between">
              <div class="text-2xl md:text-5xl" v-html="data.homePage.joinPrice" />
              <NuxtLink :to="data.homePage.joinCta.link"
                class="mt-4 md:mt-0 mx-auto md:mx-0 max-w-48 text-center oval-button dark:oval-button-dark">
                {{
                  data.homePage.joinCta.text
                }}</NuxtLink>
            </div>
          </div>
          <div class="order-1 lg:order-2 flex flex-row items-center">

            <span class="w-full  pb-10 lg:pb-20  lg:mt-0 indent-10 text-xl md:text-3xl align-middle h-full "
              v-html="data.homePage.joinCopy" />
          </div>
        </div>
      </section>

      <!-- Highlights Section -->
      <section id="highlights" class="p-8 lg:p-0 grid-area-highlights container mt-20 ">
        <h2 class="text-2xl font-bold mb-4">Highlights</h2>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-10" v-if="data.homePage.highlights" id="row1">
          <NuxtLink :to="`/events/${event.slug}`" v-for="(event, index) in data.homePage.highlights" :key="index"
            class="item opacity-0 translate-y-8 relative group transition-transform duration-300 hover:scale-105 rounded-lg">
            <DatocmsImage v-if="event.photo" :data="event.photo?.responsiveImage" critical layout="responsive"
              img-class="!h-96 object-cover rounded-lg" :alt="event.photo.alt || ''" :title="event.photo.title || ''" />

            <div
              class="absolute inset-0 flex flex-col justify-center items-center  noise text-center p-10 rounded-lg !h-96 ">
              <h4 class="text-3xl font-display p-2 bg-cream dark:bg-stone-900" v-html="event.title" />
              <p class="text-sm p-1 bg-cream dark:bg-stone-800 mt-1"
                v-html="format(event.startDate, 'iiii, MMMM d, y')" />
            </div>
          </NuxtLink>

        </div>
      </section>
    </div>

  </main>
</template>


<style scoped>
.calendar-blob {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%202000%201800%22%20fill%3D%22rgba(15%2C%2013%2C%2013%2C%200.65)%22%3E%3Cpath%20d%3D%22M70.93%20687.44C94%20439%20234.2%20293.95%20381.02%20188.24%20527.84%2082.53%20887.27-.87%201159.77%2082.53c272.5%2083.4%20553.55%20115.11%20673.78%20302.45%20120.23%20187.35%20114.36%20417.56%2087.35%20671.27-27.02%20253.71-150.35%20364.12-335.93%20543.83-185.58%20179.71-590.81%20176.19-973.73%20106.89C228.33%201637.67%20257%201468%20149.63%201259.45c-91.86-178.42-96.18-383.72-78.7-572.02Z%22%3E%3C/path%3E%3C/svg%3E');
  background-position: center center;
  background-repeat: no-repeat;
}

.join-blob {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%202000%201800%22%20fill%3D%22rgba(15%2C%2013%2C%2013%2C%200.65)%22%3E%3Cpath%20d%3D%22M70.93%20687.44C94%20439%20234.2%20293.95%20381.02%20188.24%20527.84%2082.53%20887.27-.87%201159.77%2082.53c272.5%2083.4%20553.55%20115.11%20673.78%20302.45%20120.23%20187.35%20114.36%20417.56%2087.35%20671.27-27.02%20253.71-150.35%20364.12-335.93%20543.83-185.58%20179.71-590.81%20176.19-973.73%20106.89C228.33%201637.67%20257%201468%20149.63%201259.45c-91.86-178.42-96.18-383.72-78.7-572.02Z%22%3E%3C/path%3E%3C/svg%3E');
  background-position: center center;
  background-repeat: no-repeat;

}

section {
  @apply py-8;

}

h2 {
  @apply text-3xl md:text-5xl uppercase font-bold;
}

.room-link {
  a {
    @apply text-3xl md:text-4xl lg:text-5xl text-flamingo relative inline-block w-auto;

    &::after {
      content: "";
      @apply absolute bottom-0 left-0 h-1 bg-flamingo transition-all duration-300 ease-in-out;
      width: 0;
    }

    &:hover {
      &::after {
        @apply w-full;
      }
    }
  }
}

#highlight {
  opacity: 0;
  transform: translateY(30px);
}
</style>
